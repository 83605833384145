import {ReactElement} from "react";
import {css, Theme} from "@emotion/react";

import {backgroundColor, borderRadius, calculateRemSize, display, mb, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {CREDIT_PAGES_IDS} from "@pg-mono/e2e-test-utils";

export enum GreyInfoTableVariant {
    BOX,
    BOTTOM
}
interface IProps {
    infoItems: {value: ReactElement | string; label: ReactElement | string}[];
    variant?: GreyInfoTableVariant;
}
export const GreyInfoTable = (props: IProps) => {
    const {infoItems, variant = GreyInfoTableVariant.BOX} = props;

    return (
        <div css={getBoxStyle(variant)}>
            {infoItems.map((info, index) => (
                <div key={index} css={infoWrapper} data-testid={CREDIT_PAGES_IDS.COMMON.FORM_DATA}>
                    <Text variant="info_txt_1">{info.label}</Text>
                    <Text variant="info_txt_1" strong>
                        {info.value}
                    </Text>
                </div>
            ))}
        </div>
    );
};

// Utils
const getBoxStyle = (variant: GreyInfoTableVariant) => {
    let variantStyles;

    switch (variant) {
        case GreyInfoTableVariant.BOTTOM:
            variantStyles = [borderRadius(0, 0, 1, 1)];
            break;
        case GreyInfoTableVariant.BOX:
        default:
            variantStyles = [borderRadius(1), mb(3.5)];
    }

    return [infoBoxWrapper, ...variantStyles];
};

// Styles
const infoBoxWrapper = (theme: Theme) => css`
    ${backgroundColor(theme.colors.gray[200])};
    ${p(2)};
    ${display("grid")};
    row-gap: ${calculateRemSize(0.5)};
`;

const infoWrapper = css`
    ${display("grid")};
    // For desktop
    grid-template-columns: 1.3fr 1fr;

    @media (max-width: 768px) {
        // For mobile
        grid-template-columns: 2.1fr 1fr;
    }
`;
import {ReactElement} from "react";
import {css, Theme} from "@emotion/react";

import {backgroundColor, borderRadius, calculateRemSize, display, mb, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {CREDIT_PAGES_IDS} from "@pg-mono/e2e-test-utils";

export enum GreyInfoTableVariant {
    BOX,
    BOTTOM
}
interface IProps {
    infoItems: {value: ReactElement | string; label: ReactElement | string}[];
    variant?: GreyInfoTableVariant;
}
export const GreyInfoTable = (props: IProps) => {
    const {infoItems, variant = GreyInfoTableVariant.BOX} = props;

    return (
        <div css={getBoxStyle(variant)}>
            {infoItems.map((info, index) => (
                <div key={index} css={infoWrapper} data-testid={CREDIT_PAGES_IDS.COMMON.FORM_DATA}>
                    <Text variant="info_txt_1">{info.label}</Text>
                    <Text variant="info_txt_1" strong>
                        {info.value}
                    </Text>
                </div>
            ))}
        </div>
    );
};

// Utils
const getBoxStyle = (variant: GreyInfoTableVariant) => {
    let variantStyles;

    switch (variant) {
        case GreyInfoTableVariant.BOTTOM:
            variantStyles = [borderRadius(0, 0, 1, 1)];
            break;
        case GreyInfoTableVariant.BOX:
        default:
            variantStyles = [borderRadius(1), mb(3.5)];
    }

    return [infoBoxWrapper, ...variantStyles];
};

// Styles
const infoBoxWrapper = (theme: Theme) => css`
    ${backgroundColor(theme.colors.gray[200])};
    ${p(2)};
    ${display("grid")};
    row-gap: ${calculateRemSize(0.5)};
`;

const infoWrapper = css`
    ${display("grid")};
    // For desktop
    grid-template-columns: 1.3fr 1fr;

    @media (max-width: 768px) {
        // For mobile
        grid-template-columns: 2.1fr 1fr;
    }
`;
