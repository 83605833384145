export const CREDIT_PAGES_IDS = {
    MAIN_PAGE: {
        CHECK_CREDITWORTHINESS_BUTTON: "check-creditworthiness-button",
        COMPARE_CREDITS_OFFERS_BUTTON: "compare-credits-button",
        DOWNLOAD_EBOOK: "download-ebook-button"
    },
    CREDITWORTHINESS_SUBPAGE: {
        SCHEDULE_CALL_BUTTON: "schedule-call-button",
        DOWNLOAD_CHECKLIST_BUTTON: "download-checklist-button",
        DOWNLOAD_EBOOK_BUTTON: "download-ebook-button",
        SORT_OPTIONS: "sort-options",
        CREDITWORTHINESS_DROPDOWNS: "creditworthiness-dropdowns"
    },
    COMPARE_CREDITS_SUBPAGE: {
        TITLE: "compare-credits-title",
        SUBTITLE: "compare-credits-subtitle",
        DOWNLOAD_EBOOK_BUTTON: "download-ebook-button",
        PROPERTY_TYPE: "property-type",
        APPLICANTS_COUNT: "applicants-count",
        CREDIT_AMOUNT: "credit-amount",
        CREDIT_PERIOD: "credit-period",
        SORT_TYPE: "sort-type",
        OFFERS_VALUE: "credit-offers-value",
        CREDIT_BOX_LIST: "credit-box-list",
        CREDITWORTHINESS_FORM_OPEN_BUTTON: "creditworthiness-form-open-button",
        CREDIT_OFFER_REQUEST_OPEN_BUTTON: "credit-offer-form-open-button",
        BANK_NAME_BOX: "credit-box-bank-name",
        CREDIT_TITLE_BOX: "credit-box-credit-title"
    },
    COMMON: {
        FORM_INPUT: "credit-form-input",
        FORM_CONTAINER: "credit-form-container",
        FORM_DATA: "credit-form-data",
        CREDIT_APPLICATION_MODAL: "credit-application-modal-content"
    }
};
export const CREDIT_PAGES_IDS = {
    MAIN_PAGE: {
        CHECK_CREDITWORTHINESS_BUTTON: "check-creditworthiness-button",
        COMPARE_CREDITS_OFFERS_BUTTON: "compare-credits-button",
        DOWNLOAD_EBOOK: "download-ebook-button"
    },
    CREDITWORTHINESS_SUBPAGE: {
        SCHEDULE_CALL_BUTTON: "schedule-call-button",
        DOWNLOAD_CHECKLIST_BUTTON: "download-checklist-button",
        DOWNLOAD_EBOOK_BUTTON: "download-ebook-button",
        SORT_OPTIONS: "sort-options",
        CREDITWORTHINESS_DROPDOWNS: "creditworthiness-dropdowns"
    },
    COMPARE_CREDITS_SUBPAGE: {
        TITLE: "compare-credits-title",
        SUBTITLE: "compare-credits-subtitle",
        DOWNLOAD_EBOOK_BUTTON: "download-ebook-button",
        PROPERTY_TYPE: "property-type",
        APPLICANTS_COUNT: "applicants-count",
        CREDIT_AMOUNT: "credit-amount",
        CREDIT_PERIOD: "credit-period",
        SORT_TYPE: "sort-type",
        OFFERS_VALUE: "credit-offers-value",
        CREDIT_BOX_LIST: "credit-box-list",
        CREDITWORTHINESS_FORM_OPEN_BUTTON: "creditworthiness-form-open-button",
        CREDIT_OFFER_REQUEST_OPEN_BUTTON: "credit-offer-form-open-button",
        BANK_NAME_BOX: "credit-box-bank-name",
        CREDIT_TITLE_BOX: "credit-box-credit-title"
    },
    COMMON: {
        FORM_INPUT: "credit-form-input",
        FORM_CONTAINER: "credit-form-container",
        FORM_DATA: "credit-form-data",
        CREDIT_APPLICATION_MODAL: "credit-application-modal-content"
    }
};
